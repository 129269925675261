const GoogleIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7721_46095)">
      <path
        d="M21.6989 11.2509C21.6989 10.5093 21.6388 9.76372 21.5105 9.03418H11.2207V13.2351H17.1132C16.8687 14.5899 16.083 15.7885 14.9326 16.5501V19.2759H18.448C20.5124 17.3758 21.6989 14.5699 21.6989 11.2509Z"
        fill="#4285F4"
      />
      <path
        d="M11.2193 21.9105C14.1616 21.9105 16.6428 20.9444 18.4506 19.2769L14.9352 16.5511C13.9571 17.2165 12.6945 17.5933 11.2233 17.5933C8.37732 17.5933 5.96422 15.6733 5.09838 13.0918H1.4707V15.9017C3.32262 19.5855 7.09461 21.9105 11.2193 21.9105V21.9105Z"
        fill="#34A853"
      />
      <path
        d="M5.09467 13.0913C4.63771 11.7364 4.63771 10.2693 5.09467 8.91444V6.10449H1.471C-0.0762719 9.18702 -0.0762719 12.8187 1.471 15.9012L5.09467 13.0913V13.0913Z"
        fill="#FBBC04"
      />
      <path
        d="M11.2193 4.40869C12.7746 4.38464 14.2778 4.96988 15.4042 6.04415L18.5188 2.92956C16.5466 1.07764 13.9291 0.0594876 11.2193 0.0915555C7.09461 0.0915555 3.32262 2.41648 1.4707 6.10428L5.09437 8.91423C5.9562 6.32876 8.37331 4.40869 11.2193 4.40869V4.40869Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_7721_46095">
        <rect
          width="21.8182"
          height="21.8182"
          fill="white"
          transform="translate(0.0917969 0.0908203)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
