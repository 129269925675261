import { FILE_SIZE, SUPPORTED_IMAGE_FORMATS } from '@/constants';
import { IdentityEnum } from '@/utils/enums';
import { parsePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { z } from 'zod';

export const passwordValidations = [
  {
    label: 'Minimum of 8 characters',
    test: (pw: string) => pw.length >= 8,
  },
  {
    label: 'At least 1 uppercase letter',
    test: (pw: string) => /[A-Z]/.test(pw),
  },
  {
    label: 'At least 1 lowercase letter',
    test: (pw: string) => /[a-z]/.test(pw),
  },
  {
    label: 'At least 1 number',
    test: (pw: string) => /\d/.test(pw),
  },
  {
    label: 'At least 1 special character',
    test: (pw: string) => /[^A-Za-z0-9]/.test(pw),
  },
];

import * as Yup from 'yup';

export const nameSchema = Yup.string()
  .required('This field is required') // Ensure the field is not empty
  .min(2, 'Must be at least 2 characters long') // Minimum length
  .max(50, 'Cannot be longer than 50 characters') // Maximum length
  .matches(
    /^[A-Za-z\s'-]+$/, // Allow letters, spaces, hyphens, and apostrophes
    'Can only contain letters, spaces, hyphens, and apostrophes'
  )
  .trim('Cannot start or end with a whitespace') // Remove leading and trailing whitespace
  .strict(true) // Ensure the value is a string and not another type
  .test(
    'no-leading-trailing-spaces',
    'Cannot have leading or trailing spaces',
    (value) => value === value.trim() // Ensure no leading or trailing spaces
  )
  .test(
    'no-multiple-spaces',
    'Cannot have multiple spaces in a row',
    (value) => !/\s{2,}/.test(value) // Ensure no multiple spaces
  )
  .test(
    'no-leading-hyphen-apostrophe',
    'Cannot start with a hyphen or apostrophe',
    (value) => !/^[-']/.test(value) // Ensure no leading hyphen or apostrophe
  )
  .test(
    'no-trailing-hyphen-apostrophe',
    'Cannot end with a hyphen or apostrophe',
    (value) => !/[-']$/.test(value) // Ensure no trailing hyphen or apostrophe
  )
  .test(
    'no-consecutive-hyphens-apostrophes',
    'Cannot have consecutive hyphens or apostrophes',
    (value) => !/[-']{2,}/.test(value) // Ensure no consecutive hyphens or apostrophes
  )
  .test(
    'no-repetitive-characters',
    'Cannot have repetitive characters (e.g., "aaa", "bbb")',
    (value) => !/(.)\1{2,}/.test(value) // Ensure no repetitive characters
  );

export const completeSignupValidationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
  gender: yup.string().required('This field is required'),
  dateOfBirth: yup.string().required('Date of birth is required!'),
  code: yup.string(),
});

export const signupValidationSchema = yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  emailAddress: yup.string().email('Invalid email address').required('Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone field is required.')
    .test('phoneNumber', 'Phone number not valid', (value) => {
      if (!value) return true;
      const phone = parsePhoneNumber(`${value}`);
      return phone?.isValid();
    }),
  acceptTerms: yup.boolean(),
});

export const signInValidationSchema = yup.object({
  username: yup.string().email('Invalid email address').required('Email address is required'),
  password: yup.string().required('Password is required'),
});

export const bioDataCredentialsValidationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .matches(/[A-Z]/, 'At least 1 uppercase letter')
    .matches(/[a-z]/, 'At least 1 lowercase letter')
    .matches(/\d/, 'At least 1 number')
    .matches(/[^A-Za-z0-9]/, 'At least 1 special character')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password') as any, null], 'Passwords must match')
    .required('Confirm password is required'),
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  referrerId: yup.string().optional(),
});

export const bioDataValidationSchema = yup.object({
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  referrerId: yup.string().optional(),
});

export const usernameValidationSchema = yup.object({
  username: yup
    .string()
    .required('Username is required')
    .matches(/^.*[a-zA-Z]{3,}.*$/, 'Username not valid'),
});
export const emailValidationSchema = yup.object({
  email: yup.string().email('Email address is not valid').required('Email address is required'),
});
export const changePasswordValidationSchema = yup.object({
  password: yup.string().required('Current Password is required'),
  confirmPassword: yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .matches(/[A-Z]/, 'At least 1 uppercase letter')
    .matches(/[a-z]/, 'At least 1 lowercase letter')
    .matches(/\d/, 'At least 1 number')
    .matches(/[^A-Za-z0-9]/, 'At least 1 special character')
    .required('Password is required')
    .notOneOf(
      [yup.ref('currentPassword')],
      'New password must be different from the current password'
    ),
});

export const interestValidationSchema = yup.object({
  interests: yup.array().of(yup.string().required('Select at list one interest')),
});

export const communityValidationSchema = z.object({
  // category: z.string({ required_error: 'Please select a category.' }),
  name: z.string({
    required_error: 'Name is required.',
  }),
  interest: z.string({
    required_error: 'Please select an interest.',
  }),
  type: z.string({
    required_error: 'Please the type of community.',
  }),
});

export const changeSettingsPasswordSchema = yup.object({
  currentPassword: yup.string().required('Current Password is required'),
  newPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Minimum of 8 characters')
    .matches(/[A-Z]/, 'At least 1 uppercase letter')
    .matches(/[a-z]/, 'At least 1 lowercase letter')
    .matches(/\d/, 'At least 1 number')
    .matches(/[^A-Za-z0-9]/, 'At least 1 special character')
    .notOneOf(
      [yup.ref('currentPassword')],
      'New password must be different from the current password'
    ),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .oneOf([yup.ref('newPassword')], 'Password must match new password'),
});

// Define the schema for each step
export const editEventTitleSchema = yup.object({
  // communityId: yup.string().required('Please select a community'),
  eventName: yup.string().required('Event name is required'),
  description: yup.string().optional(),
  ticketType: yup.string().required('Please select ticket type'),
  price: yup.string().when('ticketType', {
    is: '1',
    then: (schema) => schema.required('Price is required'),
    otherwise: (schema) => schema.optional(),
  }),
  numbersAvailable: yup.string().when('ticketType', {
    is: '1',
    then: (schema) => schema.required('Numbers available is required'),
    otherwise: (schema) => schema.optional(),
  }),
  base64: yup.string().nullable(),
  imageFile: yup
    .mixed<File>()
    .required('Please select an image')
    .test('fileSize', 'File size is too large', (value) => {
      if (!value) return true; // Let required handle empty files
      return value.size <= FILE_SIZE;
    })
    .test('fileType', 'Unsupported file format', (value) => {
      if (!value) return true; // Let required handle empty files
      return SUPPORTED_IMAGE_FORMATS.includes(value.type);
    }),
});

// Define the schema for each step
export const editEventDetailSchema = yup.object({
  eventDate: yup.date().required('Event date is required'),
  multiple: yup.boolean(),
  eventTime: yup.string().required('Time is required'),
  duration: yup.string().required('Duration is required'),
  venueAddress: yup.string().optional(),
  country: yup.string().optional(),
  state: yup.string().optional(),
});

export const validateIDSchema = yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  idType: yup.string().required('ID type is required'),
  nin: yup.string().when('idType', (idType, schema) => {
    return idType[0] === `${IdentityEnum.NIN}`
      ? schema
          .required('This field is required when NIN is selected')
          .matches(/^[0-9]{11}$/, 'NIN must be exactly 11 digits')
      : schema.optional();
  }),
  bvn: yup.string().when('idType', (idType, schema) => {
    return idType[0] === `${IdentityEnum.BVN}`
      ? schema
          .required('This field is required when BVN is selected')
          .matches(/^[0-9]{11}$/, 'BVN must be exactly 11 digits')
      : schema.optional();
  }),
});

export const manualIDValidationSchema = yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  nin: yup
    .string()
    .required('This field is required when NIN is selected')
    .matches(/^[0-9]{11}$/, 'NIN must be exactly 11 digits'),
});

export const bioDataSchema = yup.object({
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
});

export const publicInformationSchema = yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  username: yup.string().required('Username is is required'),
  biography: yup.string().optional(),
});

export const profileSchema = yup.object({
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  firstName: nameSchema,
  lastName: nameSchema,
  username: yup.string().required('Username is is required'),
  biography: yup.string().optional(),
  emailAddress: yup.string().optional(),
  mobileNo: yup.string().optional(),
});
