const PadLockIcon = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.75 40V50.5C91.55 50.2 89.05 50.05 86.25 50V40C86.25 24.25 81.8 13.75 60 13.75C38.2 13.75 33.75 24.25 33.75 40V50C30.95 50.05 28.45 50.2 26.25 50.5V40C26.25 25.5 29.75 6.25 60 6.25C90.25 6.25 93.75 25.5 93.75 40Z"
      fill="#FF951C"
    />
    <path
      d="M110 75V85C110 105 105 110 85 110H35C15 110 10 105 10 85V75C10 58.3 13.5 52.05 26.25 50.5C28.45 50.2 30.95 50.05 33.75 50H86.25C89.05 50.05 91.55 50.2 93.75 50.5C106.5 52.05 110 58.3 110 75Z"
      fill="#601E8A"
    />
    <path
      d="M40 84.9993C39.35 84.9993 38.7 84.8493 38.1 84.5993C37.45 84.3493 36.95 83.9994 36.45 83.5494C35.55 82.5994 35 81.2993 35 79.9993C35 79.3493 35.1499 78.6993 35.3999 78.0993C35.6499 77.4993 36 76.9493 36.45 76.4493C36.95 75.9993 37.45 75.6493 38.1 75.3993C39.95 74.5993 42.15 75.0493 43.55 76.4493C44 76.9493 44.3501 77.4993 44.6001 78.0993C44.8501 78.6993 45 79.3493 45 79.9993C45 81.2993 44.45 82.5994 43.55 83.5494C42.6 84.4494 41.3 84.9993 40 84.9993Z"
      fill="#FF951C"
    />
    <path
      d="M60 85.001C58.65 85.001 57.4 84.451 56.45 83.551C56 83.051 55.6499 82.501 55.3999 81.901C55.1499 81.301 55 80.651 55 80.001C55 78.651 55.55 77.401 56.45 76.451C58.3 74.601 61.65 74.601 63.55 76.451C64.45 77.401 65 78.651 65 80.001C65 80.651 64.8501 81.301 64.6001 81.901C64.3501 82.501 64 83.051 63.55 83.551C62.6 84.451 61.3 85.001 60 85.001Z"
      fill="#FF951C"
    />
    <path
      d="M80 85.001C78.7 85.001 77.4 84.451 76.45 83.551C75.55 82.601 75 81.351 75 80.001C75 78.651 75.55 77.401 76.45 76.451C78.35 74.601 81.7 74.601 83.55 76.451C83.75 76.701 83.9499 76.951 84.1499 77.251C84.3499 77.501 84.5001 77.801 84.6001 78.101C84.7501 78.401 84.8501 78.701 84.9001 79.001C84.9501 79.351 85 79.701 85 80.001C85 81.301 84.45 82.601 83.55 83.551C82.6 84.451 81.3 85.001 80 85.001Z"
      fill="#FF951C"
    />
  </svg>
);

export default PadLockIcon;
