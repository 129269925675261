const CheckSuccess = ({ checked }: { checked?: boolean }) => (
  <svg
    width="16"
    height="16"
    className="transition duration-500 ease-in-out"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={checked ? '#027A48' : '#fff'} />
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      stroke={checked ? '#027A48' : '#BFB6C3'}
    />
    {checked && (
      <path
        d="M11.3327 5.5L6.74935 10.0833L4.66602 8"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="transition duration-500 ease-in-out"
      />
    )}
  </svg>
);

export default CheckSuccess;
