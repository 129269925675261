import { Button, buttonVariants } from '@/components/atom/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/atom/dialog';
import { ChangePasswordModel, EmailModel } from '@/lib/models';
import { changePasswordValidationSchema, emailValidationSchema } from '@/lib/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import ValidateEmailAddress from './ValidateEmailAddress';
import { useToast } from '@/hooks/use-toast';
import useStartResetPasswordMutation from '@/hooks/react-query/auth/mutations/useStartResetPasswordMutation';
import StartPasswordResetForm from './StartPasswordResetForm';
import NewPasswordForm from './NewPasswordForm';
import { cn } from '@/lib/utils';
import PadLockIcon from '@/components/ui/icons/pad-lock-icon';
import Link from 'next/link';
import useValidatePasswordResetMutation from '@/hooks/react-query/settings/mutation/useValidatePasswordResetOTP';
import { encryptPassword } from '@/lib/encrypt';

type Props = {
  open: boolean;
  openChange?: (v?: boolean) => void;
};

const ForgotPasswordDialog = ({ open, openChange }: Props) => {
  const { toast } = useToast();
  const [email, setEmail] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [status, setStatus] = React.useState<'pending' | 'error' | 'idle' | 'success'>('idle');

  const form = useForm<EmailModel>({
    resolver: yupResolver(emailValidationSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });
  const newPasswordForm = useForm<ChangePasswordModel>({
    resolver: yupResolver(changePasswordValidationSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const startResetPassword = useStartResetPasswordMutation();
  const validatePasswordReset = useValidatePasswordResetMutation();

  const onSubmit = async (values: EmailModel) => {
    setStep(2);
  };
  const onSubmitPassword = async (otp: string) => {
    validatePasswordReset.mutate(
      {
        username: form.getValues().email,
        otp,
        password: encryptPassword(newPasswordForm.getValues().password),
      },
      {
        onSuccess: (payload) => {
          setStep(3);
          setEmail('');
          setStatus('idle');
        },
        onError: (err: any) => {
          setStatus('error');
          toast({
            variant: 'destructive',
            title: 'Error!',
            description: err || 'Unable to change password. Please try again',
          });
        },
      }
    );
  };

  const handleSubmit = () => {
    startResetPassword.mutate(
      { email: form.getValues().email },
      {
        onSuccess: () => {
          setEmail(form.getValues().email);
          setStatus('idle');
        },
        onError: (err: any) => {
          setStatus('error');
          toast({
            variant: 'destructive',
            title: 'Error!',
            description: err?.response?.data?.message || 'Unable to send OTP',
          });
        },
      }
    );
  };

  return (
    <Dialog open={Boolean(open)} onOpenChange={openChange}>
      <DialogContent className="w-screen-safe h-screen-safe md:h-fit rounded-none md:rounded-lg md:w-full md:max-w-[480px] p-4 md:p-10 antialiased flex flex-col">
        <DialogHeader className="w-full max-w-[320px] mx-auto mt-10 text-start">
          <Button
            onClick={() => openChange?.(false)}
            variant="ghost"
            size="sm"
            className={cn('text-sm px-0 mb-[59px] md:hidden', step === 3 && 'hidden')}
          >
            <ChevronLeft className="mr-1 " size={20} /> Go Back
          </Button>
          <DialogTitle className="font-medium text-2xl text-purple-brand3">
            {step === 1 && 'Enter your email address'}
            {step === 2 && 'Enter new password'}
            {step === 3 && (
              <span className="flex flex-col gap-6 items-center">
                {' '}
                <PadLockIcon />
                <p>Password Changed</p>
              </span>
            )}
          </DialogTitle>
          {step === 1 && (
            <DialogDescription className="text-sm text-gray-brand6 font-normal">
              Enter the email address used for your account
            </DialogDescription>
          )}
          {step === 3 && (
            <DialogDescription className="text-sm text-gray-brand6 font-normal text-center pb-10">
              You have successfully changed your password. Proceed to{' '}
              <Link href="/signin" className={cn(buttonVariants({ variant: 'link', size: 'sm' }))}>
                log in
              </Link>
            </DialogDescription>
          )}
        </DialogHeader>
        {step === 1 && (
          <StartPasswordResetForm
            form={form}
            onSubmit={onSubmit}
            loading={startResetPassword.isPending}
            onCancel={() => openChange?.(false)}
          />
        )}
        {step === 2 && (
          <NewPasswordForm
            form={newPasswordForm}
            onSubmit={handleSubmit}
            loading={startResetPassword.isPending}
            onCancel={() => setStep(1)}
          />
        )}
        <ValidateEmailAddress
          processing={validatePasswordReset.isPending}
          status={status}
          emailAddress={email}
          openChange={() => {
            if (status === 'error') {
              setStatus('idle');
            } else {
              setStatus('idle');
              setEmail('');
            }
          }}
          onValidateOtp={onSubmitPassword}
          onResend={() => null}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
